import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicTablePrintAndExport = _resolveComponent("BasicTablePrintAndExport")!
  const _component_InvoiceRelationDetailDialog = _resolveComponent("InvoiceRelationDetailDialog")!
  const _component_BasicTablePage = _resolveComponent("BasicTablePage")!

  return (_openBlock(), _createBlock(_component_BasicTablePage, {
    searchInputs: _ctx.searchInputs,
    searchButtons: _ctx.searchButtons,
    tableColumns: _ctx.tableColumns,
    tableData: _ctx.tableData,
    pagination: _ctx.pagination,
    summaryMethod: _ctx.mySummaryMethod,
    tableRowKey: "uGuid"
  }, {
    dialog: _withCtx(() => [
      _createVNode(_component_BasicTablePrintAndExport, {
        ref: "exportRef",
        tableColumns: _ctx.tableColumns,
        tableData: _ctx.tableData,
        fileName: "已回填发票列表"
      }, null, 8, ["tableColumns", "tableData"]),
      _createVNode(_component_InvoiceRelationDetailDialog, {
        ref: "invoiceRelationDetailDialogRef",
        onOnSuccess: _ctx.getTableData
      }, null, 8, ["onOnSuccess"])
    ]),
    _: 1
  }, 8, ["searchInputs", "searchButtons", "tableColumns", "tableData", "pagination", "summaryMethod"]))
}