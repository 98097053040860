
import { Buttons, Inputs, TableColumns } from "@/types/BasicComponent";
import { computed, defineComponent, reactive, ref, toRef } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { calcOrderInvoiceAmount, findInvoiceApplyPage, InoviceRebindData, InvoiceBackfillData, orderInvoiceBackfill, rebindInvoiceInfo } from "@/api/invoice";
import dayjs from 'dayjs'
import { useStore } from "vuex"
import { FormRulesMap } from "element-plus/lib/components/form/src/form.type";
import BasicTable from "@/components/BasicPageLayout/BasicTable.vue";
import usePagination from "@/utils/composables/usePagination";
import { copyObject } from "@/utils/common";
import BasicTableDialog from "@/components/BasicPageLayout/BasicTableDialog.vue";

export default defineComponent({
  components: {
    BasicTable,
    BasicTableDialog
},
  emits: ['on-success'],
  setup(props, { emit }) {

    const store = useStore()

    const modalShow = ref(false)
    const formData = reactive<InoviceRebindData>({
      invoiceCode: '',
      invoiceUser: '',
      amountMoney: 0,
      ilid: 0,
      invoiceDate: dayjs().toDate(),
      orderInvoiceIds: []
    })

    const formRule: FormRulesMap = {
      invoiceCode: [
        { required: true, message: '发票号不能为空' }
      ],
      invoiceDate: [
        { required: true, message: '开票日期不能为空' }
      ],
      invoiceUser: [
        { required: true, message: '开票人不能为空' }
      ],
      amountMoney: [
        { required: true, message: '开票金额不能为空' }
      ]
    }

    const pagination = usePagination()
    const tableData = ref<AnyArray>([])
    const invoiceApplyIds = computed(() => {
      if (!tableData.value) return []
      return [...new Set(tableData.value.map(td => td.oiid))]
    })
    const getTableData = async () => {
      const resp = await findInvoiceApplyPage({pageNo: 1, pageSize: 100000, invoiceId: formData.ilid})
      tableData.value = resp.data.data.records
      pagination.total = resp.data.data.total
    }
    const show = async (_data: AnyObject) => {
      copyObject(formData, _data)

      await getTableData()
      modalShow.value = true
    }

    const addInputValue = ref('')
    const searchInputs = reactive<Inputs>([
      {
        type: 'input',
        label: '订单号',
        model: addInputValue
      }
    ])
    const searchButtons = reactive<Buttons>([
      {
        label: '添加关联',
        onClick: async () => {
          if (!addInputValue.value) {
            ElMessage.warning('请输入要添加关联的订单号')
            return
          }
          const resp = await findInvoiceApplyPage({ pageNo: 1, pageSize: 1, orderNo: addInputValue.value })
          if (resp.data.data.total == 0) {
            ElMessage.warning(`未找到单号为[${addInputValue.value}]的开票申请记录`)
            return
          }
          const data = resp.data.data.records[0]
          if (data.invoice_ID) {
            ElMessage.warning(`订单[${addInputValue.value}]已与发票[${data.invoiceCode}]绑定，无法重复绑定`)
            return
          }
          if (!tableData.value.find(td => td.orderid == data.orderid))
            tableData.value.push(data)
        }
      }
    ])
    const tableColumns = reactive<TableColumns>([
      {
        prop: 'index',
        type: 'index',
        label: '序号',
        width: '60px',
        align: 'center',
        index: idx => pagination.pageOffset + idx
      }, {
        prop: 'orderCode',
        label: '订单号',
        minWidth: '200px',
        align: 'center',
      }, {
        prop: 'totalprice',
        label: '货款',
        minWidth: '120px',
        align: 'center',
      }, {
        prop: 'serviceFee',
        label: '服务费',
        minWidth: '120px',
        align: 'center',
      }, {
        prop: 'invoiceAmount',
        label: '开票总额',
        minWidth: '120px',
        align: 'center',
      }, {
        prop: 'invoiceAmount',
        label: '操作',
        type: 'button',
        minWidth: '120px',
        align: 'center',
        buttons: [
          {
            label: '解除绑定',
            onClick: async scope => {
              await ElMessageBox.confirm('是否确认解除绑定？', {
                title: '提示'
              })
              tableData.value.splice(scope.$index, 1)
            }
          }
        ]
      }
    ])

    const formRef = ref()
    const footerButtons = reactive<Buttons>([
      {
        type: 'primary',
        label: '保存',
        onClick: async () => {
          await formRef.value.validate()

          if (invoiceApplyIds.value.length == 0) {
            await ElMessageBox.confirm('当前发票未关联任何订单，保存后发票回填信息将被删除，是否确认？', { type: 'warning' })
          }

          const requestData: InoviceRebindData = {
            ...formData,
            orderInvoiceIds: invoiceApplyIds.value
          }

          await rebindInvoiceInfo(requestData)

          ElMessage.success('修改成功')
          emit('on-success')
          modalShow.value = false
        }
      },  {
        type: 'default',
        label: '关闭',
        onClick: () => modalShow.value = false
      }
    ])

    return {
      formData,
      modalShow,
      footerButtons,
      formRule,
      formRef,
      searchInputs,
      tableData,
      tableColumns,
      pagination,
      searchButtons,
      show
    }
  }
})
