
import BasicTablePage from '@/components/BasicPageLayout/BasicTablePage.vue'
import { computed, defineComponent, onActivated, onMounted, reactive, ref, toRef, watch } from 'vue'
import usePagination from '@/utils/composables/usePagination'
import { Buttons, Inputs, TableColumns } from '@/types/BasicComponent'
import { getTableColumnsSummary } from '@/utils/common'
import BasicTablePrintAndExport from '@/components/BasicPageLayout/BasicTablePrintAndExport.vue'
import { getInvoicePage, InvoiceListPageQueryParams } from '@/api/invoice'
import InvoiceRelationDetailDialog from '@/views/finance/InvoiceRelationDetailDialog.vue'

export default defineComponent({
  components: {
    BasicTablePage,
    BasicTablePrintAndExport,
    InvoiceRelationDetailDialog
  },
  setup() {
    const pagination = usePagination()
    const searchData = reactive<InvoiceListPageQueryParams>({})
    const tableData = ref<AnyArray>([])

    const getTableData = async () => {
      const res = await getInvoicePage(Object.assign({ pageNo: pagination.currentPage, pageSize: pagination.pageSize }, searchData))
      tableData.value = res.data.data.records
      pagination.total = res.data.data.total
    }
    pagination.setCallback(getTableData)

    onActivated(() => {
      getTableData()
    })

    const exportRef = ref()
    const invoiceRelationDetailDialogRef = ref()

    const searchInputs = reactive<Inputs>([
      {
        label: '发票号',
        type: 'input',
        placeholder: '发票号',
        clearable: true,
        model: toRef(searchData, 'invoiceCode')
      }, {
        label: '签收状态',
        type: 'select',
        placeholder: '请选择签收状态',
        options: [
          { label: '未签收', value: 0 },
          { label: '已签收', value: 1 }
        ],
        model: toRef(searchData, 'signStatus')
      }, {
        label: '开票日期',
        type: 'group',
        divider: '-',
        inputs: [
          {
            type: 'date',
            placeholder: '开始日期',
            clearable: true,
            model: toRef(searchData, 'invoiceDateStart')
          }, {
            label: '发票号',
            type: 'input',
            placeholder: '结束日期',
            clearable: true,
            model: toRef(searchData, 'invoiceDateEnd')
          }
        ]
      }
    ])
    const searchButtons = reactive<Buttons>([
      {
        label: '查询',
        icon: 'el-icon-search',
        onClick: getTableData
      }, {
        label: '导出',
        icon: 'el-icon-download',
        onClick: () => {
          exportRef.value.doExport()
        }
      }
    ])

    const tableColumns = reactive<TableColumns>([
      {
        prop: 'index',
        type: 'index',
        label: '序号',
        width: '70px',
        align: 'center',
        index: idx => pagination.pageOffset + idx
      }, {
        prop: 'invoiceCode',
        label: '发票号',
        minWidth: '200px',
        align: 'center'
      }, {
        prop: 'amountMoney',
        label: '开票金额',
        minWidth: '150px',
        align: 'center'
      }, {
        prop: 'invoiceUser',
        label: '开票人',
        minWidth: '150px',
        align: 'center'
      }, {
        prop: 'invoiceDate',
        label: '开票时间',
        minWidth: '150px',
        align: 'center'
      }, {
        type: 'button',
        label: '操作',
        fixed: 'right',
        buttons: [
          { 
            label: '编辑',
            onClick: scope => invoiceRelationDetailDialogRef.value.show(scope.row)
          }
        ]
      }
    ])
    
    const mySummaryMethod = (scope: AnyObject) => {
      return getTableColumnsSummary(['amountMoney'], scope.columns, scope.data)
    }
    
    return {
      getTableData,
      searchInputs,
      searchButtons,
      tableColumns,
      pagination,
      tableData,
      exportRef,
      invoiceRelationDetailDialogRef,
      mySummaryMethod
    }
  }
})
